<style>


  @page {
      size:A4 portrait;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin: 0;
      -webkit-print-color-adjust: exact;
      background: rgb(255, 255, 255);
  }

  body
    {
      background: #dae2f0;  /* fallback for old browsers */
      background: -webkit-linear-gradient(to center, #243B55, #c6c6c6);  /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to center, #243B55, #c6c6c6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    }
  </body>
  .sortable-chosen {
    opacity: 0.7;
    background-color: #dcdcdc;
  }

  .sortable-ghost {
    background-color: #dcdcdc;
  }

  .flip-list-move {
    transition: transform 0.1s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.85;
    background: #64fa6a;
  }

  .list-group {
    min-height: 20px;
  }

  <style>.image {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border: 10px solid #ebebeb;

    margin: 0 auto;


  }

  .link {
    border-top: 35px solid transparent;
    padding: 5px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    display: inline-block;
    padding-top: 78px;
    margin-top: -78px;
  }

  .head_podacha {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 10px;
    background: #f0f0f0;
    color: #717171;
    word-wrap: break-word;
    font-size: 1.2rem;
    max-width: 95%;
    min-width: 400px;
    line-height: 2.1rem;
    text-align: justify;
    border: 1px solid rgb(219, 218, 218);
    box-shadow: 2px 2px 1px rgba(142, 139, 139, 0.15);
    z-index: 35;



  }

  .head_podacha_green {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 10px;
    background: #dfffd0;
    color: #3ab800;
    font-size: 1.2rem;
    width: 95%;
    line-height: 2.1rem;
    text-align: left;
    border: 1px solid rgb(219, 218, 218);
    box-shadow: 2px 2px 1px rgba(142, 139, 139, 0.15);
    z-index: 35;



  }

  .selected {
    background-color: #f9c7c8;
    border: solid red 1px;
  }

  .flip-list-move {
    transition: transform 0.1s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.85;
    background: #64fa6a;
  }

  .list-group {
    min-height: 20px;

  }
</style>


<template>

  <v-app id="inspire" style="max-width:800px;margin:0px auto; ">




    <v-app-bar app color="#009d10" dark  style="max-width:800px; margin:0px auto;" >


      <!-- <v-divider class="mx-4" inset vertical></v-divider>
    <div class="white--text text-center shadow" style="line-height:1.15rem;font-size:1.25rem; margin-top:5px"> Документ - онлайн <br /><span style="font-size: 0.69rem;  opacity:0.9; color: #ffffff">ООО «Бюро тарифных расчетов»</span></div>
    <v-divider class="mx-4" inset vertical></v-divider>
    -->


      <v-toolbar-title >


          <table cellspacing="0" cellpadding="0" style="font-size:1.1rem; font-face:Arial; text-align:left;   width:100%; ">
            <tr>
                <td style="font-size:clamp(6pt, 4.1vw,14pt);" >




                  <span style="font-size:  22px; text-shadow: 1px 1px 2px rgba(97, 7, 145, 0.69); margin-right:5px;">Форма 46.TE.EIAS</span>



                  <a style="text-decoration:none" href="/"><v-icon  class="mr-0 "  style="color:rgb(255, 255, 255); text-shadow: 1px 1px 7px rgb(115, 246, 69); ">mdi-calendar</v-icon></a>
                    <span style="font-size:  22px; text-shadow: 1px 1px 2px rgba(97, 7, 145, 0.69);margin-left:5px;">Свод года</span>

                  </td>

            </tr>

          </table>


      </v-toolbar-title>

    </v-app-bar>





    <v-main>

      <v-alert
            border="left"
            colored-border
            color="green accent-4"
            elevation="2"
            class="mx-4 my-4 px-4 py-4"
          >
            Веб-приложение позволяет суммировать значения ежемесячных форм 46-ТЭ формата EIAS в годовую форму.
          </v-alert>



      <b style="display:block;margin:20px" >Перетащите мышкой в поле ниже 12 форм 46.TE.EIAS по месяцам или нажмите "Выбрать файлы"</b>
            <v-btn  @click="clicker_file('loader')" rounded small style="margin-left:15px ;border: 1px solid rgb(215, 215, 215)">
              <v-icon color="rgb(171, 171, 171) ">mdi-folder-search</v-icon>
              Выбрать файлы
            </v-btn>
<span class="white--text">{{trigger}}</span>


            <v-file-input v-show="false" @change="loadfiles('loader');" id="loader" small-chips multiple>

            </v-file-input>

            <div
                style="border: 2px dotted #52bd5d; max-height:300px; margin:20px;  height:300px; padding-top:20px; padding-left:20px; border-radius:10px;  "
                 @drop.prevent="addFileMessage" @dragover.prevent="snackbarOK = true">


              <p v-for="(item, index) in stru"

                   :key="item"


                   style=";line-height:0.4rem; "

              >{{index+1}}) {{item.file}}</p>

            </div>

            <v-btn large

               class=" white--text  "
               color="rgba(0, 157, 16, 0.8)"

               @click="svodgoda()" :disabled="stru.length<1" style="text-align: end; display:block;margin:20px ; margin-left: auto;
margin-right: 20;border:2px solid rgba(12, 69, 14, 0.32)"><span class="pl-2 pr-2 ">СКАЧАТЬ СВОД</span></v-btn>

<v-overlay :value="overlayer">

  <v-progress-circular
    indeterminate
    size="64"
  ></v-progress-circular><br/>
  <span class="display-1"> Пожалуйста, подождите.<br/> Свод подготавливается</span>
</v-overlay>

<v-dialog
      v-model="dialog"
      width="500"
      persistent
    >



      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Как пользоваться сводом?
        </v-card-title>

        <v-card-text class="py-4" style="font-size:14pt; color:#000000">

          <p>1) Скачайте свод по ссылке - <a class="green--text text-bold" target="_blank" :href="link">СКАЧАТЬ</a> </p>
          <p>2) <a class="green--text text-bold" target="_blank" href="https://www.data-platform.ru/tpl-web-editor/">Откройте</a> в веб-EIAS Вашу последнюю сданную форму 46 (например, за декабрь).</p>
          <p>3) На 6 строке титульного листа нажимете "ИМПОРТ ДАННЫХ", выберите скачанный файл (п.1): заполнятся значения на листе "Отпуск ТЭ" и заполнится говодой период отчёта на титульном листе.</p>
          <p>4) Загрузите статистику отчётов (кнопка на строке 85 Титульного листа), проверьте отчёт и сохраните.</p>

          <p style="color:gray;font-size:10pt">Мы не сохраняем Ваши данные на сервере. После закрытия данного окна, ссылка на свод станет недоступна.</p>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green accent-4"
            text
            @click="dialog = false; dellink();"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>





      <p class="body-2" align="center" style="color:black;border-top:1px solid #009d10;   width:100%; margin:0px auto; padding:10px;padding-top:15px; line-height:1.2rem ">
        ООО «Бюро тарифных расчетов»
    <br>


      e-mail: <a style="color:black;" href="mailto:info@btr-online.ru">info@btr-online.ru</a>
    <br>
    <a style="color:black;" href="tel:+78462216058">8 (846) 221-60-58</a>
    <br>
         <a style="color:black;" href="http://btr-online.ru">btr-online.ru</a>
      </p>

    </v-main>


  </v-app>


</template>



<script>
  // Enable pusher logging - don't include this in production
  //Pusher.logToConsole = true;



  const axios = require('axios').default


  //import draggable from "vuedraggable"
  import Vue from 'vue';
  import VueCookies from 'vue-cookies';


  Vue.use(VueCookies);














  //import html2canvas from 'html2canvas';

  Vue.filter('kb', val => {
    return Math.floor(val / 1024);
  });

  export default {
    components: {


      //draggable,
    },
    props: {
      source: String,
    },
    data: () => ({
      link:'',
      dialog:false,
      trigger:0,

      RI:[],
        loaded:'',
      rizero:false,
      ri_caption:{'TS': 'Теплоснабжение', 'HVS':'Водоснабжение','VO':'Водоотведение','GVS':'ГВС'},
      RIprogress:false,
      firms:[],
      prikazieedata:[],
      full:'',
      short:'',
      stru:[],
      mo:'',
      prikaziprogress:false,
      printvisible:false,
      girbozero:false,
      ermspprogress:false,
      egrulprogress:false,
      rosstatprogress:false,
      fnsprogress:false,
      err:{message:''},
      egruldata:[],
      mspdata:[],
      fnsdata:[],
      rosstatdata:[],
      prikazidata:[],
      inn:"",
      tab1:null,
      te:true,
      trte:false,
      tn:false,
      gvs:false,
      vs:true,
      vo:true,
      trvs:false,
      trvo:false,
      ee:true,
      osno:true,
      usno:false,
      nebudg:true,
      budg:false,
      nemp:true,
      mp:false,
      nep4:false,
      p4:true,
      do15:false,
      ot15:true,
      tereg:true,
      tenereg:false,
      helpdialog:false,
      vd:['te',	'tn',	'gvs',	'vs',	'vo',	'trte',	'trvs',	'trvo',	'ee',	'osno',	'usno',	'nebudg',	'budg',	'nemp',	'mp',	'nep4',	'p4',	'do15',	'ot15',	'tereg',	'tenereg'],
      vdtext:['Теплоснабжение',	'Теплоноситель',	'ГВС',	'Водоснабжение',	'Водоотведение',	'Транспортировка тепловой энергии',	'Транспортировка воды',	'Транспортировка стоков',	'Передача электроэнергии',	'osno',	'usno',	'nebudg',	'budg',	'nemp',	'mp',	'nep4',	'p4',	'do15',	'ot15',	'tereg',	'tenereg'],
      nalog:[],

      months:['Отчёты и документы с изменяющимися датами сдачи','Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      monthshort:['','янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
      calendar:[],
      sendingprocessdown:false,
      download:false,
      flagnow:false,
      recordobj:'',
      overlayer:false,

      flag_stop_redact:0,

                 ch1:[{message:''}],



      emailadd: false,
      sending: false,
      word: '',
      about: false,
      balance_upload: 0,
      commentaddindex: '',
      commentaddmsg: '',
      commentadd: false,

      showallperechen: true,
      tomsize_font: '16',
      expanded: [],

      tab: null,
      sel_reestr: "",
      per_direction: 2,
      centerlabel: true,
      sel_delo: {},
      sel_podacha: {},
      kind_titul: 'T',
      scale: 5,
      search: "",
      search1: "",
      move_doc: false,
      move_flag: false,
      dialog_edit_text: "",
      doc: [],
      list_dela: [],
      podacha1: {},
      files: [],
      output: null,
      org_height: 120,
      settings: false,
      dialog_titul: false,

      dialog_perechen: false,
      notice_print: false,
      label_print: false,
      label_count: 2,
      label_fontsize: 16,
      label_width: 200,
      label_height: 55,
      label_tom: " ",
      settings_pod: false,
      snackbarOK: false,
      titul_text: "",
      pd: {},
      snackbarErr: false,
      oktext: '',
      errtext: '',
      id: '0',
      show_ov_1: false,
      docs_over: [],
      show_ov_2: false,
      show_ov_3: false,
      eias_over: [],
      files_over: [],
      reestr_over: [],
      kods: [],
      file_links: [],
      index: null,
      drawer: null,
      dis: false,
      show: false,
      stroka_vivod: "",
      orgs: false,
      notes: false,
      jobs: false,
      navigation: {
        shown: true,
        width: 500,
        borderSize: 3
      },




    }),
    watch: {


    },


    computed: {







      direction() {
        return this.navigation.shown === false ? "Open" : "Closed";
      },







    },

    methods: {
      clicker_file(id) {
      document.getElementById(id).click();
    },

      addFileMessage(e) {

    this.files = [];
    let droppedFiles = e.dataTransfer.files;
    if (!droppedFiles) return;
    // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
    ([...droppedFiles]).forEach(f => {
          this.files.push(f);

        },

    );
    this.uploadMessageAtt();
  },

  uploadMessageAtt() {

    //-----
    let formData = new FormData();
    let self = this;
    formData.append('folder', self.kod);
    formData.append('enter', "123");
    this.files.forEach((f) => {
      formData.append('docs[]', f);
    });

    axios.post('https://46.btr-online.ru/loaddelo',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    ).then(function(data) {

      let load=data.data.split("----")
      load.pop()
      window.console.log(load)
      self.stru=[]
      load.forEach(x=>{
        let elem = x.split('****')[1]
        elem=elem.split(';')
        elem.pop()
        self.stru.push({file:x.split('****')[0],pages:elem})
      })
      window.console.log(self.stru)
      self.loaded=self.stru.map(x=>x.pages).flat();

      window.console.log( self.loaded);

      Vue.set(self,'trigger',self.trigger=self.trigger+1)





    })
        .catch(function() {
          window.console.log('FAILURE!!');
        });



  },


  svodgoda() {

    //-----

    let formData = new FormData();
    let self = this;

    formData.append('enter', "123");
    formData.append('stru', self.stru.map(x=>x.pages));
    self.overlayer=true;



    axios.post('https://46.btr-online.ru/svodgoda',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    ).then(function(data) {


      window.console.log(data)
      self.stru=[]
      self.link=data.data
      self.overlayer=false;
      self.dialog=true


      Vue.set(self,'trigger',self.trigger=self.trigger+1)





    })
        .catch(function() {
          window.console.log('FAILURE!!');
        });



  },

  methodRefreshPage() {
      // ...
      location.reload();
      // ...
    },

  dellink() {

    //-----

    let formData = new FormData();
    let self = this;

    formData.append('enter', "123");
    formData.append('link', self.link);




    axios.post('https://46.btr-online.ru/dellink',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    ).then(function(data) {



      window.console.log(data)



      Vue.set(self,'trigger',self.trigger=self.trigger+1)
      self.methodRefreshPage();





    })
        .catch(function() {
          window.console.log('FAILURE!!');
        });



  },



      loadfiles(id) {
  this.files = [];
  let droppedFiles = document.getElementById(id).files;
  if (!droppedFiles) return;



  // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
  ([...droppedFiles]).forEach(f => {
        this.files.push(f);
        this.balance_upload += 1

      },

  );

  this.uploadMessageAtt();


},



      printoutcalendar()
      {
                //
                // let divToPrint = window.document.getElementById("calendarprint");
                // let newWin = window.open('');
                // newWin.document.write(divToPrint.outerHTML);
                // newWin.print();



                window.setTimeout(() => {
                const prtHtml = document.getElementById('calendarprint').innerHTML;

                // Get all stylesheets HTML
                let stylesHtml = '';

                for (const node of [...document.querySelectorAll('link[rel="stylesheet"]')]) {
                  stylesHtml += node.outerHTML;
                }


                // Open the print window

                const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

                WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                    ${stylesHtml}
                  </head>
                  <body style="background: rgb(255, 255, 255);">
                    ${prtHtml}
                  </body>
                </html>`);



                  WinPrint.document.close();
                  WinPrint.focus();

                  window.setTimeout(() => {WinPrint.focus(); WinPrint.print()}, 2000)
                  this.printvisible=false;
                }, 1000)
                  //WinPrint.print();
                  //WinPrint.close();



            },

      validateInn(inn, error) {
 var result = false;
 if (typeof inn === 'number') {
   inn = inn.toString();
 } else if (typeof inn !== 'string') {
   inn = '';
 }
 if (!inn.length) {

   error.message = 'ИНН пуст';
 } else if (/[^0-9]/.test(inn)) {

   error.message = 'ИНН может состоять только из цифр';
 } else if ([10, 12].indexOf(inn.length) === -1) {

   error.message = 'ИНН может состоять только из 10 или 12 цифр';
 } else {
   var checkDigit = function (inn, coefficients) {
     var n = 0;
     for (var i in coefficients) {
       n += coefficients[i] * inn[i];
     }
     return parseInt(n % 11 % 10);
   };
   switch (inn.length) {
     case 10:
       var n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
       if (n10 === parseInt(inn[9])) {
         result = true;
       }
       break;
     case 12:
       var n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
       var n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
       if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
         result = true;
       }
       break;
   }
   if (!result) {

     error.message = 'Неправильное контрольное число';
   }
 }
 if (result)
 {
   this.girbozero=false;this.rizero=false;this.egruldata=[];this.mspdata={'data':[]};this.fnsdata=[];this.rosstatdata=[]; this.prikazieedata=[]; this.full=''; this.short='';this.mo=''
   this.prikazidata=[]; this.RI=[]; this.getRI();this.rosstat();this.girbo();this.egrul();this.msp(); this.prikaziee();
   this.err.message=""
 }
 else {
   this.err.message=error.message
 }

 return result;
},

      rosstat()
      {
        var self = this;
        self.rosstatprogress=true;
        axios.post(
            'https://calendar.btr-online.ru/Getrosstat',
            JSON.stringify({
                enter: "123",
                inn:self.inn,
            })
          )
          .then(function(response) {
            if (
              response.data != "ERROR") {
                self.rosstatdata=response.data;
                self.rosstatprogress=false;

            } else {
              self.rosstatprogress=false;
            }
          })
          .catch(function(error) {
            window.console.log(error);
            self.rosstatprogress=false;
          });
      },


            prikazi()
            {



              var self = this;
              var short='--';
              var full='--';
              var mo="--";
              self.prikaziprogress=true;

              if (self.short!='')
              {
                if (self.short.indexOf('"')>-1)
                {
                  short='«'+self.short.match(/"(.+)"/)[1]+'»'
                }
                else {
                  short='«'+self.short+'»'
                }

              }

              if (self.full!='')
              {
                if (self.full.indexOf('"')>-1)
                {
                  full='«'+self.full.match(/"(.+)"/)[1]+'»'
                }
                else {
                  full='«'+self.full+'»'
                }

              }

              if(self.fnsdata.okopf.name.indexOf('Муниц')>-1)
              {

                if (self.fnsdata.city)
                {
                  mo=self.fnsdata.city.slice(0,-3)
                }
                else {
                  mo=self.fnsdata.district.slice(0,-3)
                }



              }

              axios.post(
                  'https://calendar.btr-online.ru/prikazi',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,
                      short: short,
                      mo:mo,
                      full:  full,
                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.prikazidata=response.data;
                      self.prikaziprogress=false;

                  } else {
                    self.prikaziprogress=false;
                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.prikaziprogress=false;
                });
            },
            prikazi2()
            {



              window.setTimeout(() => {



                var self = this;
                var short='--';
                var full='--';
                var mo='--';

                this.log('prikazi2');
                self.prikaziprogress=true;
                if (self.egruldata)
                {

                  if ((self.egruldata['rows'][0].k=='fl'))

                  {


                    self.short=self.egruldata['rows'][0].n.split(" ")[0].slice(0,-2);
                    self.full=self.egruldata['rows'][0].n.split(" ")[1].slice(0,1)+'.'+self.egruldata['rows'][0].n.split(" ")[2].slice(0,1)+'.';
                    self.prikazi3();
                    return;


                  }

                 else {



                   if (self.egruldata['rows'][0].c!='')
                   {
                     if (self.egruldata['rows'][0].c.indexOf('"')>-1)
                     {
                       short='«'+self.egruldata['rows'][0].c.match(/"(.+)"/)[1]+'»'
                     }
                     else {
                       short='«'+self.egruldata['rows'][0].c+'»'
                     }

                   }

                   if (self.egruldata['rows'][0].n!='')
                   {
                     if (self.egruldata['rows'][0].n.indexOf('"')>-1)
                     {
                       full='«'+self.egruldata['rows'][0].n.match(/"(.+)"/)[1]+'»'
                     }
                     else {
                       full='«'+self.egruldata['rows'][0].n+'»'
                     }

                   }

                 }
               }






                axios.post(
                    'https://calendar.btr-online.ru/prikazi',
                    JSON.stringify({
                        enter: "123",
                        inn:self.inn,
                        short:short,
                        mo:mo,
                        full: full,

                    })
                  )
                  .then(function(response) {
                    if (
                      response.data != "ERROR") {
                        self.prikazidata=response.data;
                        self.prikaziprogress=false;

                    } else {
                      self.prikaziprogress=false;
                    }
                  })
                  .catch(function(error) {
                    window.console.log(error);
                    self.prikaziprogress=false;
                  });


              }, 3000)


            },

            prikazi3()
            {
              var self = this;
              self.prikaziprogress=true;

              axios.post(
                  'https://calendar.btr-online.ru/prikaziip',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,
                      short: self.short,
                      full:  self.full
                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.prikazidata=response.data;
                      self.prikaziprogress=false;

                  } else {
                    self.prikaziprogress=false;
                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.prikaziprogress=false;
                });
            },
            prikaziee()
            {
              var self = this;
              self.prikaziprogress=true;

              axios.post(
                  'https://calendar.btr-online.ru/prikaziee',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,

                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.prikazieedata=response.data;
                      self.prikaziprogress=false;

                  } else {
                    self.prikaziprogress=false;
                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.prikaziprogress=false;
                });
            },
            getRI()
            {
              var self = this;
              self.prikaziprogress=true;

              axios.post(
                  'https://calendar.btr-online.ru/GetRINFO',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,

                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.RI=response.data;
                      self.RIprogress=false;
                      if (self.RI.length===0)
                      {
                          self.rizero=true;

                      }

                  } else {
                    self.RIprogress=false;

                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.RIprogress=false;
                });
            },

      girbo()
      {
        var self = this;
        self.fnsprogress=true;
        axios.post(
            'https://calendar.btr-online.ru/girbo',
            JSON.stringify({
                enter: "123",
                inn:self.inn,
            })
          )
          .then(function(response) {
            if (response.data != "ERROR") {
                self.fnsdata=response.data;
                self.fnsprogress=false;
                self.full=self.fnsdata['fullName'];
                self.short=self.fnsdata['shortName'];
                self.prikazi()

            } else {
            self.fnsprogress=false;
            self.girbozero=true;
            self.prikazi2();



            }
          })
          .catch(function(error) {
            window.console.log(error);
            self.fnsprogress=false;
            self.prikazi2();

          });
      },

      egrul()
      {
        var self = this;
        self.egrulprogress=true;
        axios.post(
            'https://calendar.btr-online.ru/egrul',
            JSON.stringify({
                enter: "123",
                inn:self.inn,
            })
          )
          .then(function(response) {
            if (
              response.data != "ERROR") {
                self.egruldata=response.data;
                self.egrulprogress=false;

            } else {

              self.egrulprogress=false;
            }
          })
          .catch(function(error) {
            window.console.log(error);
            self.egrulprogress=false;
          });
      },
      msp()
      {
        var self = this;
        self.ermspprogress=true;
        axios.post(
            'https://calendar.btr-online.ru/Getmsp',
            JSON.stringify({
                enter: "123",
                inn:self.inn,
            })
          )
          .then(function(response) {


            try {


            if ( (  response.data !== "ERROR") && (('ERROR' in response.data)==false)  ) {
                self.mspdata=response.data;
                self.ermspprogress=false;

            } else
            {
              self.ermspprogress=false;
            }
          }
            catch(error) {
              window.console.log(error);
              self.ermspprogress=false;
            }
          })

      },

      refresh() {
    window.location.reload();
},
       convertToBoolean(sss) {
          try {
              return JSON.parse(sss.toLowerCase());
          }
          catch (e) {
              return undefined;
          }
      },

      savecookie()
      {

          this.vd.forEach((item) => {
              this.$cookies.set(item, this[item],31556926);
          });

      },


showwidth()
{
  return window.document.documentElement.clientWidth
},


      reportfilter(elem)
      {
        var  result=false;



     for (var i = 2; i < 9; i++) {
        result=result || (((this[this.vd[i]]+0)+(elem[this.vd[i]]+0))===2)

     }


     if ((this['te']+0)===1)
     {
         if ((this['tereg']+0)===1)  {
            result=result || ((elem['tereg']+0)===1)
         }
         else {
           result=result || ((elem['tenereg']+0)===1)
         }
      }

      if ((this['tn']+0)===1)
      {
          if ((this['tereg']+0)===1)  {
             result=result || (((elem['tereg']+0)===1) && ((elem['tn']+0)===1))
          }
          else {
            result=result || (((elem['tenereg']+0)===1) && ((elem['tn']+0)===1))
          }
       }


     for (var j = 9; j < 19; j++) {

       if ((this[this.vd[j]]+0)===1) {
          if (((elem[this.vd[j]]+0)===1))
          {
             result=result && ((elem[this.vd[j]]+0)===1)
          }
          else {
            result=false;
            break;
          }
       }



     }



        return (result )
      },

      calcreports(massiv)
      {
        let sum=0

        massiv.forEach((item) => {

          for (var i = 1; i < 13; i++) {
            sum=sum+((item['MON'+i]!==null)+0)
          }
          sum=sum+((item['specdata']!==null)+0)

        });

        return sum
      },













      Isnoproblem(str) {
        try {
          str.trim();
        } catch (e) {
          return true;
        }
        return false;
      },

      log(dd) {
        window.console.log(dd)
      },


      getid() {

        var self = this;
        axios.post(
            'https://calendar.btr-online.ru/Getcalendar',
            JSON.stringify({
                enter: "123",
            })
          )
          .then(function(response) {
            if (
              response.data != "ERROR") {
              self.calendar = (response.data);

            } else {
              window.alert("Код не найден!");
            }
          })
          .catch(function(error) {
            window.console.log(error);
          });

      },
      getfirms() {

        var self = this;
        axios.post(
            'https://calendar.btr-online.ru/Getfirms',
            JSON.stringify({
                enter: "123",
            })
          )
          .then(function(response) {
            if (
              response.data != "ERROR") {
              self.firms = (response.data);

            } else {
              window.alert("Код не найден!");
            }
          })
          .catch(function(error) {
            window.console.log(error);
          });

      },




      styleDoc(i, img) {

        return "backgroundImage: url(" + img + "); width: " + 680 * this.zoom[i] / 100 + "px; height: " + 963 * this.zoom[i] / 100 + "px; transform: rotate(" + this.rot[i] + "deg);"
      },
      setBorderWidth() {
        let i = this.$refs.drawer.$el.querySelector(
          ".v-navigation-drawer__border"
        );
        i.style.width = this.navigation.borderSize + "px";
        i.style.cursor = "ew-resize";
      },
      setEvents() {
        const minSize = this.navigation.borderSize;
        const el = this.$refs.drawer.$el;
        const drawerBorder = el.querySelector(".v-navigation-drawer__border");
        const vm = this;
        const direction = el.classList.contains("v-navigation-drawer--right") ?
          "right" :
          "left";

        function resize(e) {
          document.body.style.cursor = "ew-resize";
          let f = direction === "right" ?
            document.body.scrollWidth - e.clientX :
            e.clientX;
          el.style.width = f + "px";
        }

        drawerBorder.addEventListener(
          "mousedown",
          function(e) {
            if (e.offsetX < minSize) {
              ///m_pos = e.x;
              el.style.transition = 'initial';
              document.addEventListener("mousemove", resize, false);
            }
          },
          false
        );

        document.addEventListener(
          "mouseup",
          function() {
            el.style.transition = '';
            vm.navigation.width = el.style.width;
            document.body.style.cursor = "";
            document.removeEventListener("mousemove", resize, false);
          },
          false
        );
      },



    },

    mounted() {


















    },

  };
</script>
